import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import index from '../views/index.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: index
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/about.vue')
  },
  {
    path: '/production',
    name: 'production',
    component: () => import('../views/production.vue')
  },
  {
    path: '/service',
    name: 'service',
    component: () => import('../views/service.vue')
  },
  {
    path: '/strength',
    name: 'strength',
    component: () => import('../views/strength.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('../views/contact.vue')
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import('../views/privacy.vue')
  },
  {
    path: '/agreement',
    name: 'agreement',
    component: () => import('../views/agreement.vue')
  },
  {
    path: '/pdfFIle',
    name: 'pdfFIle',
    component: () => import('../views/pdfFIle.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
