
  import { defineComponent, onMounted, ref, reactive } from "vue";
  import { useRouter, useRoute } from "vue-router";
  import HeaderView from "@/components/HeaderView.vue";
  import FooterView from "@/components/FooterView.vue";

  // 引入swiper
  import Swiper, {
    Autoplay,
    EffectCoverflow,
    EffectCube,
    Pagination,
    Navigation,
  } from "swiper";
  Swiper.use([Autoplay, EffectCoverflow, EffectCube, Pagination, Navigation]);

  // swiper-bundle.min.css 决定了小圆点和左右翻页标签，如果不需要可以不引用
  import "swiper/swiper-bundle.min.css";

  // swiper.less/sass/css 决定了基础的样式
  import "swiper/swiper.min.css";

  export default defineComponent({
    name: "HomeView",
    components: {
      HeaderView,
      FooterView,
    },
    setup() {
      onMounted(() => {
        // new Swiper(".swiper1", {
        //   //循环
        //   loop: true,
        //   //每张播放时长3秒，自动播放
        //   spaceBetween: 25,
        //   pagination: {
        //     el: ".swiper-pagination",
        //   },
        //   autoplay: {
        //     delay: 3000,
        //     stopOnLastSlide: false,
        //     disableOnInteraction: false,
        //   },
        // });
      });
      const router = useRouter(),
        route = useRoute();
      function btn() {
        router.push({ name: "production", params: { cur: "0" } });
      }
      function aboutBtn() {
        router.push({ name: "about", params: { cur: "0" } });
      }
      function aboutBtn1() {
        router.push({ name: "contact" });
      }
      return { btn, aboutBtn, aboutBtn1 };
    },
  });
