import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
//引入element-plus
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css'
// 引入公共样式
import './assets/sass/common.scss'
// 引入图标样式
import "@/assets/font/iconfont.css";
import VueAMap, {initAMapApiLoader} from '@vuemap/vue-amap';
import '@vuemap/vue-amap/dist/style.css'
initAMapApiLoader({
  key: "3ebb9a3eea189b5e08d595afc9452e9f",
  plugins: [
    "AMap.Autocomplete",
    "AMap.PlaceSearch",
    "AMap.Scale",
    "AMap.OverView",
    "AMap.ToolBar",
    "AMap.MapType",
    "AMap.PolyEditor",
    "AMap.CircleEditor",
    "AMap.Geocoder",
    "AMap.Geolocation"
  ],
});

import 'animate.css'
import 'animate.css/animate.compat.css'

createApp(App).use(store).use(router).use(VueAMap).use(ElementPlus).mount('#app')


